<template>
    <main>
        <div
            v-if="!makingMeal"
        
            class="dd-u-bg-white dd-u-center-both dd-u-flex dd-u-flex-col dd-u-fixed dd-u-items-center dd-u-justify-center dd-u-z-50"
            style="padding: 2rem;"
        >
            <p
                class="dd-c-paragraph"
                style="margin-top: 0;"
            >Ready to make turkey meatballs with tomato basil sauce?</p>

            <button
                class="dd-c-button dd-v-button-primary"
                style="margin-top: 1rem !important;"
                type="button"
                
                @click="makingMeal = true; window.scrollTo(0, document.body.scrollHeight);"
            >
                Let's go!
            </button>
        </div>
        
        <div
            v-if="!makingMeal"
        
            class="dd-u-fixed dd-u-h-full dd-u-w-full dd-u-z-40"
            style="background-color: rgba(0, 0, 0, .8);"
        ></div>
        
        <div class="dd-u-min-h-screen dd-u-overflow-hidden dd-u-relative">
            <div class="c-machine dd-u-absolute dd-u-flex dd-u-left-1/2 dd-u-min-h-full dd-u-justify-center dd-u-z-20 js-ingredients">
                <Ingredient
                    v-for="ingredient in ingredients"
                    :key="ingredient.key"

                    :added="ingredient.added"
                    :condiment="ingredient.condiment"
                    :name="ingredient.name"
                    :positionX="-1 * (((currentIngredientIndex + 1) * 100) - 50)"
                />
            </div>

            <div class="u-bottom-32 dd-u-flex dd-u-flex-col dd-u-fixed dd-u-left-0 dd-u-items-center dd-u-justify-between dd-u-w-full dd-u-z-20">
                <div class="dd-u-flex dd-u-relative">
                    <button
                        class="dd-c-button dd-v-button-secondary"
                        :disabled="currentIngredientIndex === 0"
                        type="button"

                        @click="previousIngredient()"
                    >
                        <span class="dd-u-sr-only">Previous</span> ←
                    </button>

                    <button
                        class="u-mx-16 dd-c-button dd-v-button-primary"
                        :disabled="isPrimaryButtonDisabled"
                        type="button"

                        @click="triggerPrimaryButton()"
                    >
                        {{ primaryButtonText }}
                    </button>

                    <button
                        class="dd-c-button dd-v-button-secondary"
                        :disabled="currentIngredientIndex === ingredients.length"
                        type="button"

                        @click="nextIngredient()"
                    >
                        <span class="dd-u-sr-only">Next</span> →
                    </button>
                </div>
            </div>

            <div class="c-bowl-container u-pb-80 dd-u-bottom-0 dd-u-fixed dd-u-w-full dd-u-z-0 js-bowl-container">
                <img
                    alt=""
                    class="c-bowl dd-u-absolute dd-u-center-x dd-u-z-10"
                    src="svg/bowl-back.svg"
                >

                <img
                    alt=""
                    class="c-meatball c-meatball-1 dd-u-absolute dd-u-z-20 js-meatball js-meatball-1"
                    src="svg/meatball.svg"
                >

                <img
                    alt=""
                    class="c-meatball c-meatball-2 dd-u-absolute dd-u-z-20 js-meatball js-meatball-2"
                    src="svg/meatball.svg"
                >

                <img
                    alt=""
                    class="c-meatball c-meatball-3 dd-u-absolute dd-u-z-20 js-meatball js-meatball-3"
                    src="svg/meatball.svg"
                >
                
                <div class="js-sauce-stream" style="background-color: #a20000; left: 50%; height: 0vh; position: absolute; top: -1vh; transform: translate(-50%, 0%); width: .5rem; z-index: 30;"></div>

                <div style="background-color: #4a4a4a; bottom: 16vh; left: -20%; height: 12vh; width: 150%; position: absolute; z-index: 0;">
                    
                </div>

                <img
                    alt=""
                    class="c-basil c-basil-1 dd-u-absolute dd-u-z-20 js-basil js-basil-1"
                    src="svg/basil-1.svg"
                >

                <img
                    alt=""
                    class="c-basil c-basil-2 dd-u-absolute dd-u-z-20 js-basil js-basil-2"
                    src="svg/basil-2.svg"
                >

                <img
                    alt=""
                    class="c-basil c-basil-3 dd-u-absolute dd-u-z-20 js-basil js-basil-3"
                    src="svg/basil-3.svg"
                >

                <img
                    alt=""
                    class="c-feta c-feta-1 dd-u-absolute dd-u-z-20 js-feta js-feta-1"
                    src="svg/feta-1.svg"
                >

                <img
                    alt=""
                    class="c-feta c-feta-2 dd-u-absolute dd-u-z-20 js-feta js-feta-2"
                    src="svg/feta-2.svg"
                >

                <img
                    alt=""
                    class="c-feta c-feta-3 dd-u-absolute dd-u-z-20 js-feta js-feta-3"
                    src="svg/feta-3.svg"
                >

                <img
                    alt=""
                    class="c-bowl dd-u-absolute dd-u-center-x dd-u-z-50"
                    src="svg/bowl-front.svg"
                >
            </div>
        </div>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import Ingredient from '@/components/Ingredient';

export default {
    name: 'Home',
    components: {
		Ingredient,
	},
    data() {
        return {
            currentIngredientIndex: 0,
            ingredients: [
                {
                    added: false,
                    filename: 'turkey-meatballs',
                    name: 'Meatballs',
                },
                {
                    added: false,
                    condiment: true,
                    filename: 'tomato-sauce',
                    name: 'Tomato Sauce',
                },
                {
                    added: false,
                    filename: 'basil',
                    name: 'Basil',
                },
                {
                    added: false,
                    filename: 'feta',
                    name: 'Feta',
                },
            ],
            makingMeal: false
        }
    },
    mounted() {
        window.scrollTo(0, document.body.scrollHeight);

        gsap.to('.js-ingredients', {
            duration: 1,
            ease: "elastic.out(1, 0.65)",
            x: (this.currentIngredientIndex * (100 / this.ingredients.length)) * -1 + '%'
        });

        gsap.set('.js-meatball, .js-basil, .js-feta', {
            opacity: 0,
            x: '-50%',
            y: '-=200%'
        });
    },
    methods: {
        getCurrentIngredient() {
            return this.ingredients[this.currentIngredientIndex];
        },
        getCurrentIngredientAttribute: function(attribute) {
            const currentIngredient = this.getCurrentIngredient();

            if (currentIngredient) {
                return currentIngredient[attribute];
            } else {
                return '';
            }
        },
        nextIngredient() {
            this.currentIngredientIndex++;

            gsap.to('.js-ingredients', {
                duration: 1,
                ease: "elastic.out(1, 0.65)",
                x: '-=' + (100 / this.ingredients.length) + '%'
            });
        },
        previousIngredient() {
            this.currentIngredientIndex--;

            gsap.to('.js-ingredients', {
                duration: 1,
                ease: "elastic.out(1, 0.65)",
                x: '+=' + (100 / this.ingredients.length) + '%'
            });
        },
        triggerPrimaryButton() {
            const currentIngredient = this.getCurrentIngredient();

            if (currentIngredient) {
                if (currentIngredient.condiment) {
                    gsap.to('.js-sauce', {
                        duration: 4,
                        ease: "power2.out",
                        y: '+=20'
                    });
                } else {
                    gsap.to('.js-ingredient-' + currentIngredient.filename, {
                        duration: 4,
                        ease: "power2.out",
                        y: '+=20'
                    });
                    
                    const tl = gsap.timeline();

                    tl.to('.js-bowl-container', {
                        duration: .5,
                        ease: 'power2.inOut',
                        x: '-40'
                    });
                    tl.to('.js-bowl-container', {
                        delay: 1,
                        duration: 1,
                        ease: 'power2.inOut',
                        x: '40'
                    });
                    tl.to('.js-bowl-container', {
                        delay: 1,
                        duration: .5,
                        ease: 'power2.inOut',
                        x: '0'
                    });

                    tl.play();
                }

                if (currentIngredient.filename === 'turkey-meatballs') {
                    gsap.to('.js-meatball-3', {
                        delay: 1,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });

                    gsap.to('.js-meatball-1', {
                        delay: 2.5,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });

                    gsap.to('.js-meatball-2', {
                        delay: 4.5,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });
                }
                
                if (currentIngredient.filename === 'tomato-sauce') {
                    gsap.to('.js-sauce-stream', {
                        duration: 2,
                        height: '15vh',
                        ease: "power2.out",
                    });
                    
                    gsap.to('.js-sauce-stream', {
                        delay: 2,
                        duration: 1,
                        height: '0vh',
                        top: '15vh',
                        ease: "power2.out",
                    })
                }
                
                if (currentIngredient.filename === 'basil') {
                    gsap.to('.js-basil-3', {
                        delay: 1,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });

                    gsap.to('.js-basil-1', {
                        delay: 2.5,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });

                    gsap.to('.js-basil-2', {
                        delay: 4.5,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });
                }
                
                if (currentIngredient.filename === 'feta') {
                    gsap.to('.js-feta-3', {
                        delay: 1,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });

                    gsap.to('.js-feta-1', {
                        delay: 2.5,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });

                    gsap.to('.js-feta-2', {
                        delay: 4.5,
                        duration: 1,
                        ease: "power2.out",
                        opacity: '1',
                        x: '-50%',
                        y: '0'
                    });
                }

                currentIngredient.added = true;
            } else {
                window.location.href = 'https://www.gofeedme.club';
            }
        },
    },
    computed: {
        isCurrentIngredientAdded: function() {
            const currentIngredient = this.getCurrentIngredient();

            if (currentIngredient) {
                return currentIngredient.added;
            } else {
                return false;
            }
        },
        isPrimaryButtonDisabled: function() {
            const currentIngredient = this.getCurrentIngredient();

            if (currentIngredient) {
                return currentIngredient.added;
            } else {
                return false;
            }
        },
        primaryButtonText: function() {
            const currentIngredient = this.getCurrentIngredient();

            if (currentIngredient) {
                return 'Add ' + currentIngredient.name;
            } else {
                return 'Return to Site';
            }
        },
    },
}
</script>

<style lang="scss">
    .c-machine {
        width: 100vh;
    }

    .c-meatball,
    .c-basil,
    .c-feta {
        left: 50%;
        top: 10vh;
        width: 9vh;
    }

    .c-meatball-1,
    .c-basil-1,
    .c-feta-1 {
        margin-left: -7vh;
    }

    .c-meatball-3,
    .c-basil-3,
    .c-feta-3 {
        margin-left: 7vh;
    }

    .c-bowl {
        top: 10vh;
        width: 34vh;
    }

    .c-bowl-container {
        height: 50vh;
        position: relative;
    }

    .dd-c-button {
        letter-spacing: 0 !important;
        margin-top: 0 !important;
        padding-bottom: .5rem !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        padding-top: .5rem !important;
    }

    .dd-c-button.dd-v-button-primary {
        font-size: .9rem !important;
        width: 12rem;
    }

    .u-bottom-32 {
        bottom: 2rem;
    }

    .u-pb-80 {
        padding-bottom: 5rem;
    }

    .u-mx-16 {
        margin-left: 1rem;
        margin-right: 1rem;
    }
</style>
